import http from '../services/http'
import { Base64 } from 'js-base64';

export default {
  namespaced: true,
  actions: {
    find(_, params) {
      const request = params ? http(`/templates?${params}`) : http('/templates');

      return request.then(response => JSON.parse(response.body))
        .catch(error => { throw error });
    },
    findById(_, id) {
      return http(`/templates/${id}`)
        .then(response => {
          let template = JSON.parse(response.body)
          template.html = Base64.decode(template.html);
          template.css = Base64.decode(template.css);
          return template;
        })
        .catch(error => {throw error});
    },
    destroy(_, template) {
      return http(`/templates/${template.id}`, { method: 'DELETE' })
        .then(response => response.body)
        .catch(error => {throw error});
    },
    duplicate(_,template){
      template.default = false;
      return http(`/templates`, { method: 'POST', body: JSON.stringify(template) })
        .then(response => JSON.parse(response.body))
        .catch(error => {throw error});
    },
    save(_, template) {
      const request = template.id
        ? http(`/templates/${template.id}`, { method: 'PUT', body: JSON.stringify(template) })
        : http('/templates', { method: 'POST', body: JSON.stringify(template) })

      return request.then(response => response.body)
                    .catch(error => {throw error});
    },
    ping(){
      return http('/templates/ping', { method: 'POST', showLoader: false})
        .then(response => JSON.parse(response.body));
    },
    clone(_,id){
      return http(`/templates/clone/${id}`, { method: 'POST' })
        .then(response => JSON.parse(response.body))
        .catch(error => {throw error});
    }
  }
}
