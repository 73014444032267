import http from '../services/http'

export default {
  namespaced: true,

  actions: {
    find(_, params) {
      const request = params ? http(`/images?${params}`) : http('/images');

      return request.then(response => JSON.parse(response.body))
        .catch(error => { throw error });
    },
    findById(_, id) {
      return http(`/images/${id}`)
        .then(response => JSON.parse(response.body))
        .catch(error => { throw error });
    },
    destroy(_, image) {
      return http(`/images/${image.id}`, { method: 'DELETE' })
        .then(response => response.body)
        .catch(error => { throw error });
    },
    save(_, image) {
      const request = image.id
        ? http(`/images/${image.id}`, { method: 'PUT', body: JSON.stringify(image) })
        : http('/images', { method: 'POST', body: JSON.stringify(image) })

      return request.then(response => JSON.parse(response.body))
        .catch(error => { throw error });
    }
  }
}
