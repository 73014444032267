<template>
  <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel">
    <v-card>
      <v-toolbar dark :color="options.color" dense v-if="title">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text cardTextMargin>
        <v-form ref="form">
          <v-text-field
            label="Nombre"
            clearable
            color="editor"
            v-model="form.name"
            required
            :rules="nameRules"
          ></v-text-field>
          <v-text-field
            label="Descripción"
            clearable
            color="editor"
            v-model="form.description"
            required
            :rules="descriptionRules"
          ></v-text-field>
          <v-select
            :items="items"
            :label="$t('views.templates.edittemplate.templateData.selectExpirationLabel')"
            item-text="name"
            @change="expirationSelected"
            item-value="key"
            :rules="expirationSelectRules"
            v-model="keySelected"
          ></v-select>
          <v-text-field 
            v-if="isVisibleExpirationDays"
            type="number" 
            :label="$t('views.templates.edittemplate.templateData.inputDaysPlaceholder')" 
            v-model="form.expirationDays" 
            :rules="daysRules"
          ></v-text-field>
          <v-date-picker 
            v-if="isVisibleExpirationDate"
            landscape 
            show-current 
            full-width  
            v-model="form.expirationDate" 
            locale="es-ES" 
            :max="maxExpirationDate" 
            :min="minExpirationDate"
          ></v-date-picker>
          <v-checkbox v-if="form.showCheckEditionForm"
            v-model="form.blockFormEdition"
            :label="$t('views.templates.edittemplate.templateData.blockEditionLabel')"
          ></v-checkbox>
        </v-form>
      </v-card-text>
      <v-card-text v-show="!!message">{{ message }}</v-card-text>
      <v-card-actions> 
        <v-spacer></v-spacer>
        <v-btn :color="options.color" text @click="agree">{{ options.yesLabel }}</v-btn>
        <v-btn text @click="cancel">{{ options.noLabel }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, minLength, maxLength, numberLessOrEqualThan } from "@/validation";

export default {
  props: {
    message: String,
    title: String,
    paramsOptions: Object,
    paramsForm: Object
  },
  data: () => ({
      dialog: false,
      options: {
        color: "editor",
        width: 550,
        yesLabel: "Yes",
        noLabel: "No"
      },
      form: {
        name: "",
        description: "",
        expirationDays: null,
        expirationDate: "",
        showCheckEditionForm: null,
        blockFormEdition: false
      },
      inputVisible: null,
      keySelected: null,
      items: [ {key: 0, name : 'Días desde el envío'}, {key: 1, name :'Fecha máxima'}],
      nameRules: [required, minLength(1), maxLength(28)],
      descriptionRules: [required, minLength(5), maxLength(250)],
      expirationSelectRules: [required],
      daysRules: [],
  }),
  computed: {
    isVisibleExpirationDays(){
      return (this.inputVisible == 0)
    },
    isVisibleExpirationDate(){
      return (this.inputVisible == 1)
    },
    maxExpirationDate() {
      let oneYearFromNow = new Date();
      oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
      return oneYearFromNow.toISOString().substr(0, 10)
    },
    minExpirationDate() {
      return new Date().toISOString().substr(0, 10)
    }
  },
  methods: {
    agree() {
      const isValid = this.$refs.form.validate(true);
      if (!isValid) {
        return;
      }
      this.dialog = false;
      this.deleteUnselected();
      this.$emit("close", {
        result: true,
        name: this.form.name,
        description: this.form.description,
        expirationDays: this.form.expirationDays,
        expirationDate: this.form.expirationDate,
        blockFormEdition: this.form.blockFormEdition
      });
    },
    cancel() {
      this.dialog = false;
      this.$emit("close", { result: false });
    },
    open() {
      this.dialog = true;
    },
    expirationSelected(key) {
       this.daysRules = [];
      if(key == 0){
        this.inputVisible = 0;
        this.keySelected = this.items[0];
        this.daysRules = [required, numberLessOrEqualThan(365)];
      }
      else if(key == 1) {
        if(!this.form.expirationDate){
          this.form.expirationDate = new Date().toISOString().substr(0, 10)
        }
        this.inputVisible = 1;
         this.keySelected = this.items[1];
      }
      /*else {
        this.form.expirationDays = null;
        this.form.expirationDate = null;
        this.inputVisible = null;
      }*/
    },
    onCreatedVisible() {
      if(this.form.expirationDays){
        this.inputVisible = 0;
        this.keySelected = this.items[0];
      }else if(this.form.expirationDate){
        this.inputVisible = 1;
        this.keySelected = this.items[1];
      }
      else {
        this.inputVisible = null;
        this.keySelected = this.items[2];
      }
    },
    deleteUnselected() {
      if(this.inputVisible == 0){
        this.form.expirationDate = "";
      } else {
        this.form.expirationDays = 0;
      }
    }
  },
  created() {
    if(this.paramsForm.expirationDate){
      this.paramsForm.expirationDate = this.paramsForm.expirationDate.substr(0, 10);
    }
    Object.assign(this.options, this.paramsOptions);
    Object.assign(this.form, this.paramsForm);
    this.onCreatedVisible();
  }
};
</script>