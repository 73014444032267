<template>
  <v-app blue>
    <MenuLateral></MenuLateral>
    <v-main>
      <MenuSuperior></MenuSuperior>
      <v-container fluid ref="container">
        <router-view :key="$route.fullPath"></router-view>
      </v-container>
    </v-main>
    <v-snackbar
      v-for="notification in notifications"
      :key="notification.id"
      :timeout="notification.timeout"
      :color="notification.type"
      top
      center
      @input="removeNotification(notification)"
      :value="true"
    >
      {{ notification.message }}
      <v-btn text @click.native="removeNotification(notification)">&times;</v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import MenuLateral from "@/components/core/MenuLateral";
import MenuSuperior from "@/components/core/MenuSuperior";
import { mapActions, mapState, mapGetters } from "vuex";
import { setRefs } from "./services/http";

export default {
  components: {
    MenuLateral,
    MenuSuperior
  },
  computed: {
    ...mapState("notifications", {
      notifications: "stack"
    })
  },
  methods: {
    ...mapActions("notifications", { removeNotification: "remove" })
  },
  mounted() {
    setRefs(this.$refs.container);
  }
};
</script>
<style lang="scss">
@import "./css/app.scss";
</style>