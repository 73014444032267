let COUNTER = 0

export default {
  namespaced: true,

  state: {
    stack: []
  },

  mutations: {
    add(state, pMessage) {
      pMessage.id = pMessage.id || ++COUNTER
      const lMessage = {
        id: pMessage.id,
        timeout: pMessage.timeout,
        type: pMessage.type,
        message: pMessage.message.msg ? pMessage.message.msg : pMessage.message
      }
      state.stack.push(lMessage)
    },

    remove(state, message) {
      state.stack = state.stack.filter(m => m !== message)
    }
  },

  actions: {
    info({ commit }, message) {
      commit('add', {
        timeout: message.timeout || 5000,
        type: 'info',
        message
      })
    },

    success({ commit }, message) {
      commit('add', {
        timeout: message.timeout || 3000,
        type: 'success',
        message
      })
    },

    error({ commit }, message) {
      commit('add', {
        timeout: message.timeout || 5000,
        type: 'error',
        message
      })
    },

    remove({ commit }, message) {
      commit('remove', message)
    }
  }
}
