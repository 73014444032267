import TemplateMetaTags from '../../components/templates/TemplateMetaTags.vue'

export function templateMetaTagsPlugin(Vue) {
  const TemplateMetaTagsComponent = Vue.extend(TemplateMetaTags)

  Vue.prototype.$templateMetaTags = function(message, title, paramsOptions = {}, paramsForm = {}) {
    const dialog = new TemplateMetaTagsComponent({
      parent: this.$root,
      propsData: {
        message,
        title,
        paramsOptions,
        paramsForm
      }
    })
    dialog.$mount()
    document.body.appendChild(dialog.$el)
    dialog.open()

    return new Promise((resolve) => {
      dialog.$once('close', (isAccepted) => {
        document.body.removeChild(dialog.$el)
        resolve(isAccepted)
      })
    })
  }
}
