<template>
  <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel">
    <v-card>
      <v-toolbar dark :color="options.color" dense v-if="title">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="cardTextMargin" v-show="!!message" v-html="message"></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :color="options.color" text @click="agree">{{ options.yesLabel }}</v-btn>
        <v-btn text @click="cancel">{{ options.noLabel }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    message: String,
    title: String,
    params: Object
  },
  data: () => ({
      dialog: false,
      options: {
        color: "primary",
        width: 400,
        yesLabel: "Yes",
        noLabel: "No"
      } 
  }),
  methods: {
    agree() {
      this.dialog = false;
      this.$emit("close", true);
    },

    cancel() {
      this.dialog = false;
      this.$emit("close", false);
    },

    open() {
      this.dialog = true;
    }
  },
  created() {
    Object.assign(this.options, this.params);
  }
};
</script>
<style lang="scss">
.cardTextMargin {
  margin-top: 1em;
}
</style>
