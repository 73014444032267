<template>
  <v-toolbar dark color="secondary">
    <v-btn icon @click="changeVisibleMenuLocal" v-if="isLoggedIn">
      <v-icon>{{mdiMenuPath}}</v-icon>
    </v-btn>
    <component :is="mainLogo" height="3em"></component>
    <v-toolbar-title class="font-weight-light headline pa-4">{{ pageTitle }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-menu v-if="isLoggedIn">
      <template v-slot:activator="{ on }">
        <v-chip class="ma-2" color="white" label v-on="on">
          <v-icon left color="secondary">{{mdiAccountCirclePath}}</v-icon>
          <span style="color: #004B87">
            <b>{{userName}}</b>
          </span>
        </v-chip>
      </template>
      <v-list>
        <v-list-item :to="{ name: 'profile' }">
          <v-icon>{{mdiFaceProfilePath}}</v-icon>
          <v-list-item-title><b style="font-size: 1em; padding-left: 0.5em">{{$t('views.app.perfil')}}</b>
          </v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item class="pa-1" @click="logout" v-bind:title="$t('common.buttons.logout')">
          <v-btn block color="secondary">{{$t('common.buttons.logout')}}</v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-toolbar>
</template>

<script>
import { mdiFaceProfile, mdiAccountCircle, mdiMenu } from '@mdi/js';
import { mapActions, mapState, mapGetters } from "vuex";
import mainLogo from '@/assets/customIcons/mainLogo.svg?inline';

export default {
  name: "MenuSuperior",
   data: () => ({
      mdiFaceProfilePath: mdiFaceProfile,
      mdiAccountCirclePath: mdiAccountCircle,
      mdiMenuPath: mdiMenu,
      searchText: null,
      mainLogo : mainLogo
  }),
  computed: {
    ...mapState(["pageTitle"]),
    ...mapGetters(["isLoggedIn", "isVisibleMenu", "userName"]),
  },
  methods: {
    ...mapActions(["changeVisibleMenu"]),
    changeVisibleMenuLocal() {
      this.changeVisibleMenu(!this.isVisibleMenu);
    },
    logout() {
      return this.$store.dispatch("logout").then(() => {
        this.$router.replace("/login");
      });
    }
  }
};
</script>