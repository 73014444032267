import Vue from 'vue';
import Vuex from 'vuex';
import { abilityPlugin, ability as appAbility } from './ability';
import notifications from './notifications';
import templates from './templates';
import customer from './customer';
import images from './images';
import http from '../services/http';
import createPersistedState from 'vuex-persistedstate';
import router from '@/router';

Vue.use(Vuex);

export const ability = appAbility

export const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      key: 'tmtc',
      reducer: state => ({
        token: state.token,
        vM: state.vM,
        userName: state.userName
      }),
      storage: window.sessionStorage
    }),
    abilityPlugin
  ],

  modules: {
    notifications,
    templates,
    images,
    customer
  },
  state: {
    token: '',
    pageTitle: 'Landing Pages',
    vM: false,
    userName: ''
  },
  getters: {
    isLoggedIn(state) {
      return !!state.token;
    },
    accessToken(state) {
      return state.token;
    },
    isVisibleMenu(state){
      return state.vM;
    },
    userName(state) {
      return state.userName;
    }
  },
  mutations: {
    createSession(state, session) {
      state.token = session;
    },
    destroySession(state) {
      state.token = '';
      localStorage.removeItem('tmt');
      state.userName = null;
    },
    changePageTitle(state, value){
      state.pageTitle = value
    },
    changeVisibleMenu(state, value){
      state.vM = value;
    },
    setUserName(state, userName){
      state.userName = userName;
    }
  },
  actions: {
    login({commit}, details) {
      return http('/login', {method: 'POST',body: JSON.stringify(details)})
        .then(response => {
          if(response){
            commit('setUserName', details.UserName);
            commit('createSession', response.body);
            const mq = window.matchMedia( "(min-width: 500px)" );
            if (mq.matches) {
              commit('changeVisibleMenu', true);
            } 
          }
        })
        .catch(error => {
          throw error;
        });
    },
    logout({state, commit}) {
      return http('/logoff', {method: 'POST'})
        .then(() => {
          commit('changeVisibleMenu', false);
          commit('destroySession');
          state.pageTitle = 'Landing Pages';
        }); 
    },
    unauthorized({state, commit}){
        commit('changeVisibleMenu', false);
        commit('changePageTitle', "Landing Pages");
        commit('destroySession');
        router.push('/login');
    },
    setTitle({commit}, value) {
      commit('changePageTitle', value);
    },
    changeVisibleMenu({commit}, value){
      commit('changeVisibleMenu', value);
    }
  }
})

http.onError = response => {
  if (response.status === 403) {
    store.dispatch('notifications/error', response.body.message)
    return true
  }
}