import { Ability } from '@casl/ability';
import jwtDecode from 'jwt-decode';

export const ability = new Ability();

export const abilityPlugin = (store) => {

  if(store.state.token){
    const jwtDecoded = jwtDecode(store.state.token);
    ability.update(jwtDecoded.rules);
  }

  return store.subscribe((mutation) => {
    switch (mutation.type) {
    case 'createSession':
      const jwtDecodedSession = jwtDecode(mutation.payload);
      ability.update(jwtDecodedSession.rules);
      break;
    case 'destroySession':
      ability.update([{}]);
      break;
    }
  })
}
