import Vue from 'vue';
import Vuetify from 'vuetify';
import { abilitiesPlugin } from '@casl/vue';
import { confirmPlugin, templateMetaTagsPlugin, templateDataPlugin } from './plugins';
import App from './App';
import router from './router';
import { store, ability } from './store';
import 'vuetify/dist/vuetify.min.css';
import colors from 'vuetify/lib/util/colors';
import i18n from '@/i18n/index';

Vue.config.productionTip = false;

Vue.use(Vuetify);
Vue.use(confirmPlugin);
Vue.use(templateMetaTagsPlugin);
Vue.use(templateDataPlugin);
Vue.use(abilitiesPlugin, ability);

const vuetifyOptions = {
  theme: {
    themes: {
      light: {
        primary: '#005c99',
        secondary: '#004B87',
        accent: colors.teal.darken2,
        error: colors.red.accent4,
        warning: colors.yellow.accent2,
        info: colors.blue.base,
        success: colors.green.accent3,
        editor: '#004B87'
      }
    }
  },
  icons: {
    iconfont: 'mdiSvg'
  }
}

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  vuetify: new Vuetify(vuetifyOptions),
  render: h => h(App)
});

window.addEventListener("storage", function (e) {
  if (e.key == "tmtc" && ((e.oldValue != '' && e.newValue == null) || e.oldValue == null && e.newValue != '' || e.oldValue != e.newValue)) {
    store.dispatch('logout');
    router.go('/login')
  }
}, false);