import http from '../services/http'

export default {
  namespaced: true,
  actions: {
    find(_) {
        return http(`/customer/data`, { method: 'GET'})
        .then(response => {
          if(response.body != "") {
            return JSON.parse(atob(response.body))
          }
        })
        .catch(error => {throw error});
    },
    save(_, userData) {    
      let objJsonB64 = btoa(JSON.stringify(userData));
      let data = `{ "data" : "${objJsonB64}" }`;

      return http(`/customer/data`, { method: 'PUT', body: data})
        .then(response => response.body)
        .catch(error => {throw error});
    },
  }
}
