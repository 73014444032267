import Vue from 'vue';
import VueI18n from 'vue-i18n';
import es from './messages/es.json'
import pt from './messages/pt.json'

Vue.use(VueI18n);

const locale = 'es';

const messages = {
    es: es,
    pt: pt,
};

const i18n = new VueI18n({
    locale,
    messages
});

export default i18n;