import TemplateData from '../../components/templates/TemplateData'

export function templateDataPlugin(Vue) {
  const TemplateDataComponent = Vue.extend(TemplateData)

  Vue.prototype.$templateData = function(message, title, paramsOptions = {}, paramsForm = {}) {
    const dialog = new TemplateDataComponent({
      parent: this.$root,
      propsData: {
        message,
        title,
        paramsOptions,
        paramsForm
      }
    })
    dialog.$mount()
    document.body.appendChild(dialog.$el)
    dialog.open()

    return new Promise((resolve) => {
      dialog.$once('close', (isAccepted) => {
        document.body.removeChild(dialog.$el)
        resolve(isAccepted)
      })
    })
  }
}
