import Vue from 'vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(Loading);

let loader = null;

export function hideLoader() {
    if (loader) {
        loader.hide();
        loader = null;
    }
}

export function showLoader(container) {
    hideLoader();
    loader = Vue.$loading.show({
        container: container ? container : null,
        color: '#005c99',
        loader: 'spinner',
        width: 24,
        height: 24,
        opacity: 0,
    })
}