<template>
  <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel">
    <v-card>
      <v-toolbar dark :color="options.color" dense v-if="title">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text cardTextMargin>
        <v-form ref="form">
          <v-text-field
            label="Título"
            clearable
            color="editor"
            v-model="form.title"
            required
            :rules="titleRules"
          ></v-text-field>
          <v-select
            :items="images"
            item-value="name"
            label="Imagen"
            v-model="form.image"
            @input="onInput"
            clearable>
            <template v-slot:selection="data">
              {{ data.item.name }}
            </template>
            <template v-slot:item="data">
              <img :src="data.item.src" width="60" />
              <span>{{ data.item.name }}</span>
            </template>
          </v-select>
          <v-alert dense text justify type="info">{{ $t("views.templates.edittemplate.templateMetaTags.infoText") }}</v-alert>
        </v-form>
      </v-card-text>
      <v-card-text v-show="!!message">{{ message }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :color="options.color" text @click="agree">{{ options.yesLabel }}</v-btn>
        <v-btn text @click="cancel">{{ options.noLabel }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import { mdiInformation } from "@mdi/js";
import { minLength, maxLength } from "@/validation";

export default {
  props: {
    message: String,
    title: String,
    paramsOptions: Object,
    paramsForm: Object,
  },
  data: () => ({
    dialog: false,
    mdiInfoPath: mdiInformation,
    keySelected: "",
    images: [],
    options: {
      color: "editor",
      width: 550,
      yesLabel: "Yes",
      noLabel: "No",
    },
    form: {
      title: "",
      image: "",
      id: "",
    },
    titleRules: [minLength(5)],
  }),
  methods: {
    ...mapActions("images", {
      getImages: "find",
    }),
    agree() {
      const isValid = this.$refs.form.validate(true);
      if (!isValid) {
        return;
      }
      this.dialog = false;
      this.$emit("close", {
        result: true,
        data: JSON.stringify(this.form),
      });
    },
    cancel() {
      this.dialog = false;
      this.$emit("close", { result: false });
    },
    open() {
      this.dialog = true;
    },
    onInput(img){
      const data = this.images.find(x => x.name === img)
      if(data) {
        this.form.id = data.id
      }
    },
    async getMappedImages() {
      let imgs = await this.getImages();
      this.images = imgs.map((x) => {
        let srcSplited = x.src.split("/");
        return  {
          id: x.id,
          name: srcSplited[srcSplited.length - 1],
          src: x.src
        };
      });
    },
  },
  created() {
    this.getMappedImages();
    Object.assign(this.options, this.paramsOptions);
    Object.assign(this.form, this.paramsForm.templateMetadata ? JSON.parse(this.paramsForm.templateMetadata) : {});
  },
};
</script>