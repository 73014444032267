<template>
  <v-navigation-drawer app mini-variant mobile-breakpoint :value="isVisibleMenu">
    <v-list class="pt-0" v-if="isLoggedIn">
      <v-list-item-group color="primary">
        <v-list-item v-for="(element, i) in elements" :key="i" @click="route(element)">
          <v-list-item-icon >
             <v-icon :color="element.color" v-if="element.icon">{{element.icon}}</v-icon>
             <component :is="element.component" v-else height="1.5em"></component>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{$t(element.text)}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>   
  </v-navigation-drawer>
  
</template>

<script>
import { mapGetters } from "vuex";
import { mdiPlusCircle, mdiHome } from '@mdi/js';
import customTemplateLogo from '@/assets/customIcons/menuLateral/customTemplate.svg?inline';
import defaultTemplateLogo from '@/assets/customIcons/menuLateral/defaultTemplate.svg?inline';

export default {
  name: "MenuLateral",
  data: () => ({
    elements: [
      {
        to: "index",
        icon: mdiHome,
        color: "primary",
        text: "common.home",
        permission: ["read", "template"]
      },
      {
        to: "newTemplate",
        icon: mdiPlusCircle,
        color: "accent",
        text: "common.create",
        permission: ["create", "template"]
      },
      {
        to: "templates",
        component: customTemplateLogo,
        color: "#4d687c",
        text: "common.custom",
        query: {default : false},
        permission: ["read", "template"]
      },
       {
        to: "templates",
        component: defaultTemplateLogo,
        color: "#283c4c",
        text: "common.default",
        query: {default : true},
        permission: ["read", "template"]
      },
      /*{
        to: "help",
        icon: "mdi-help-circle",
        color: "black",
        text: "common.help",
        permission: ["read", "template"]
      }*/
    ]
  }),
  methods: {
    route(element){
      if (element.hasOwnProperty('query')){
        this.$router.push({name: element.to, query: element.query});
      }
      else {
        this.$router.push({name: element.to});
      }
    }
  },
  computed: {
    ...mapGetters(["isLoggedIn", "isVisibleMenu"])
  }
};
</script>