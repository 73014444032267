import { store } from '../store';
import { hideLoader, showLoader } from '@/js/loader';

const API_URL = process.env.VUE_APP_APIURL;

var formContainer = null;

const textErr500 = "Se ha producido un error, contacte con Temática Software";

export function setRefs(container) {
  formContainer = container
}

export default function http(url, { headers, ...options } = {}) {
  if (options.showLoader == undefined) showLoader(formContainer);
  return fetch(`${API_URL}${url}`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Access-Token': store.getters.accessToken,
      ...headers
    },
    ...options
  }).then(response => {
    hideLoader();
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      return response.json().then(body => ({
        body,
        status: response.status
      }))
    } else if (response.status >= 400 && response.status <= 500) {
      return response.text().then(body => {
        let isJson = false;
        var str;

        try {
          JSON.parse(body);
          isJson = true;
        } catch (error) {
          isJson = false;
        }

        str = isJson ? `{ "body" : ${body} ,"status" : ${response.status} }` : `{ "body" : { "Codigo": 999, "Mensaje": "${body}" },"status" : ${response.status} }`;

        let parsed;
        try {
          parsed = JSON.parse(str);
        } catch (error) {
          parsed = JSON.parse(`{ "body" : { "Codigo": 999, "Mensaje": "${textErr500}" },"status" : ${response.status} }`);
        }

        return parsed;
      })
    };
  }).then(response => {
    var err500 = null;
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else if (response.status == 401) {
      store.dispatch('unauthorized');
    } else if (response.status == 500) {
      err500 = textErr500;
    }
    if (!http.onError(response)) {
      throw {
        message: err500 ? err500 : response.body.Mensaje,
        error: new Error(response.body.Mensaje)
      };
    }
  }).catch(e => {
    hideLoader();

    let errMsg;
    if (e.message == 'Failed to fetch') {
      errMsg = textErr500;
    } else {
      errMsg = e.message;
    }

    throw {
      message: errMsg,
      error: new Error(e)
    };
  })
};