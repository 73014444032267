import Vue from 'vue';
import Router from 'vue-router';
import { store } from '@/store/index';


const Index = () => import('@/views/templates/Index')
const Login = () => import('@/views/login/Login')
const Autologin = () => import('@/views/login/AutoLogin')
const EditTemplate = () => import('@/views/templates/EditTemplate')
const Template = () => import('@/views/templates/Template')
const Templates = () => import('@/views/templates/TemplateList')
const Profile = () => import('@/views/user/Profile')
const Help = () => import('@/views/help/Help')

import { ability } from '@/store/ability';

Vue.use(Router);

let router = new Router({
  routes: [
    {
      path: '/',
      name: 'index',
      component: Index,
      meta: {
        permission: 'read',
        object: 'template'
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { requiresAuth: false }
    },
    {
      path: '/autologin',
      name: 'autologin',
      component: Autologin,
      meta: { requiresAuth: false }
    },
    {
      path: '/templates',
      name: 'templates',
      component: Templates,
      meta: {
        permission: 'read',
        object: 'template'
      }
    },
    {
      path: '/templates/:id/edit',
      name: 'editTemplate',
      component: EditTemplate,
      meta: {
        permission: 'update',
        object: 'template'
      }
    },
    {
      path: '/templates/new',
      name: 'newTemplate',
      component: EditTemplate,
      meta: {
        permission: 'create',
        object: 'template'
      }
    },
    {
      path: '/templates/:id',
      name: 'template',
      component: Template,
      meta: {
        permission: 'read',
        object: 'template'
      }
    },
    {
      path: '/user/profile',
      name: 'profile',
      component: Profile,
      meta: {
        permission: 'read',
        object: 'userprofile'
      }
    },
    {
      path: '/user/help',
      name: 'help',
      component: Help,
      meta: {
        permission: 'read',
        object: 'userprofile'
      }
    },
    {
      path: '*',
      component: Index
    }
  ]
});

router.beforeEach((to, from, next) => {
  var requiresAuth = to.matched.some(record => typeof record.meta.requiresAuth === 'undefined');
  if (requiresAuth && !store.getters.isLoggedIn) {
    next('/login');
  } else if (to.path == '/login' && store.getters.isLoggedIn) {
    next('/');
  } else {
    if (store.getters.isLoggedIn && !ability.can(to.meta.permission, to.meta.object)) {
      next('/login');
    }
    next();
  }
});

export default router;